<template>
    

    <div>
        <div class="login-hd">
            <div><img src="/Image/yj_login.svg" height="44" /></div>
        </div>
     
        <div class="login-panel">


            <div class="login-left"><h1 class="name">迎驾<br />让获客更简单</h1>  <img src="/Image/login-img.webp" width="360" style="vertical-align:middle" /></div>
            <div class="login-form">
                <div class="logos">
                    <img  src="/Image/yjlogo.svg" />
                </div>
              
                <div :class="show?'pass-login':'scan-login'" @click="showwx"> </div>
            <div v-show="show">
           <div class="login-title weixin"><img src="/Image/weixin.svg" style="width:32px;height:32px;margin-right:10px" />微信扫码登录</div> 
           <div class="wx-qr" @click="refreshqr2"><img :src="qrimg" alt="二维码" style='width:180px;height:180px;margin-top:35px;border:1px solid #eee' /></div>
          </div>
                <a-form-model ref="ruleForm" :model="FormModel" :rules="rules" v-bind="layout" v-show="show2">
                      <div class="login-title">密码登录</div>
                     <a-form-model-item has-feedback prop="UserName" style="margin:40px 0">
                        <a-input size="large" v-model="FormModel.UserName" type="text" autocomplete="off" placeholder="输入账号" class="login-input"  >
                            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item has-feedback prop="Password" style="margin:40px 0">
                        <a-input-password size="large" @keyup="mykeyup" v-model="FormModel.Password" placeholder="输入密码" autocomplete="off" class="login-input">
                            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25);" />
                        </a-input-password>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 24 }" style="margin:70px 0 20px">
                        <a-button size="large" type="primary" :block="true" @click="submitForm('ruleForm')" class="login-but">
                            登录
                        </a-button>

                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 24 }" v-if="MessageShow.Show">
                        <a-alert :message="MessageShow.Text" :type="MessageShow.Type" show-icon />
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
        <div class="login-fd">
            <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41050202000067"> <img src="/Image/baicon.png" height="22" />豫公网安备 41050202000067号</a> | Copyright &copy; 2009-{{fullyear}} 安阳市军博软件有限公司 | <a href="https://beian.miit.gov.cn">豫ICP备14013436号</a><a href="https://xinyong.yunaq.com/certificate?domain=https://www.ay001.net"> <img src="/Image/vsite.png" height="24"/></a></p>
        </div>
    </div>

</template>
<script>
    import "ant-design-vue/dist/antd.css";
    import "@/assets/css/yjht.css";
    import Vue from "vue";
    import ant from "ant-design-vue";
    Vue.use(ant);
    import http from "@/Plugin/Http.js";
    import Account from "@/Plugin/Account.js";
 
import { setTimeout } from "core-js";

  
    var matchmax = 60;
    var matchcount = 0;
    var matchlock = 0;

    export default {
        name: "Login",
        data() {
            return {
                show:false,
                show2:true,
                MessageShow: {
                    Text: "",
                    Show: false,
                    Type: "error"
                },
                FormModel: {
                    UserName: '',
                    Password: ''
                },
                rules: {
                    UserName: [
                        { validator: this.InputChange },
                        { message: "请输入账号", required: true }

                    ],
                    Password: [
                        { validator: this.InputChange },
                        { message: "请输入密码", required: true }

                    ]
                },
                layout: {
                    wrapperCol: { span: 24 },
                },
                qrimg:"",
                fullyear: ''             
            };
        },
      
        methods: {
            showwx:function(){
                (this.show = !this.show) && (this.refreshqr(), 1) || this.closelisten();
                this.show2=!this.show2;
            },
            InputChange: function (rule, value, callback) {
                this.MessageShow.Show = false;
                callback();
            },
            mykeyup: function (e) {
                e && e.keyCode && e.keyCode == 13 && this.submitForm('ruleForm');
            },
            submitForm(formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        Account.Login(this.FormModel.UserName, this.FormModel.Password, function (msg) {
                            self.MessageShow.Text = msg;
                            self.MessageShow.Show = true;
                            self.MessageShow.Type = "error";
                        });
                    } else {
                        return false;
                    }
                });
            },
            refreshqr: function () {
                this.qrimg = "/Image/loading.gif";
                var self = this;
                var op = {
                    url: "/Account/LoginQR",
                    data: {},
                    OnSuccess: function (response) {
                        self.qrimg = response.data.QrCode;
                        self.startlisten(response.data.QrKey);
                    }
                };
                http.Post(op);
            },
            refreshqr2: function () {
                matchcount < 1 && this.refreshqr();
            },
            startlisten: function (qrid) {
                var self = this;
                matchcount = matchmax;
                setTimeout(this.qrmatch, 2000, qrid, function () {
                    self.closelisten();
                    self.qrimg = "/Image/loading.gif";   
                    Account.LoginHome();
                }, function () {
                    self.closelisten();
                });
            },
            qrmatch: function (qrid, matchok, matchout) {                
                var self = this;
                !matchlock && (--matchcount > 0 && (http.Post({
                    url: "/Account/QRMatch",
                    data: { "id": qrid },
                    OnSuccess: function (response) {
                        matchlock=0,response.data && (matchok && matchok(),1)||setTimeout(self.qrmatch,2000,qrid,matchok,matchout);                       
                    }
                }), 1) || (matchout && matchout()));
            },
            closelisten: function () {
                this.qrimg = "/Image/failqr.jpg";               
                matchlock = matchcount = 0;
            }
        },
        created: function () {
            var year = new Date().getFullYear();
            this.fullyear = year;
        },
        mounted: function () {
            this.FormModel.UserName = process.env.VUE_APP_TestUser;
            this.FormModel.Password = process.env.VUE_APP_TestPassword;
        },
        computed: {
        },
        components: {}
    };
</script>
<style>
 
    body {background:#f3f7f9;overflow:auto;min-width:1100px;}
    
     .login-input .ant-input{height:48px;line-height:48px;border-radius:60px;box-sizing:border-box;background-color:#f0f2f5;border:1px solid #e0e2e5;}
</style>
<style scoped>

.pass-login{position: absolute;right:10px;top:10px;cursor: pointer;background: url('/Image/xt.png') no-repeat 5px bottom;width:46px;height:46px;display: inline-block;}
.scan-login{position: absolute;right:10px;top:10px;cursor: pointer;background: url('/Image/xt.png') no-repeat 5px top;width:46px;height:46px;display: inline-block;}
    .logos {
    display:none;}
    .login-title {
        height: 48px;
        line-height: 48px;
        font-size:1.1rem;
        color: #444;
        font-weight: 400;
        text-align: left;
        font-family: "微软雅黑";
        letter-spacing: 2px;
    }
    .name {
    font-size:26px;
    color:#666;
    font-weight:400;padding:25px;
    }
    .login-panel {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: 100px auto;
    border-radius:8px;   
    overflow:hidden;
    box-shadow:0 0 10px 0 rgba(12,1,4,0.05)
    
    }
 
    .login-left {
        width: 430px;
        height: 600px;
        background-image:linear-gradient(90deg,#fff 75%,#f2f2f3);
        color:#666;padding:0 40px;
    }
    .login-form{   
        position: relative;
    width:100%;
    background:#fff;
    padding:100px 130px;
    
  }
    .login-hd{background-color:#fff;height:80px;line-height:80px;box-shadow:0 2px 8px #e0e2e5;}
    .login-hd>div{margin:0  auto;text-indent:32px;}
    .login-hd>div img{height:34px;}
    .login-fd {
        text-align: center;
        padding-top: 10px;
        color: #666;
        position:fixed;
        bottom: 0;
        width: 100vw;
        height: 60px;
        font-size: 0.78rem;
    }
        .login-fd a{color:#666;}
    .login-fd a img{margin:0 4px;vertical-align:middle;}
    html, body {
        position: relative;
        margin: 0;
        padding: 0px;
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 2.1rem;
        font-family: "arial, helvetica, sans-serif,";
    }

    .login-body {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg,#2465FC, #4946F8);
        background-image: -webkit-linear-gradient(45deg,#2465FC, #4946F8);
        position: relative;
        overflow: hidden;
       
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        padding: 0;
    }

    img {
        vertical-align: middle;
    }
 
   
    .login-but{height:48px;line-height:48px;border-radius:60px;box-sizing:border-box;font-size:20px}
   /* @media only screen and (max-width:768px) {
        .login-left {
    display:none;    
    }
        .login-panel {
    width:100vw;
    height:100vh;
    margin:0 auto;
    border-radius:0;
    }
        .login-form {
     padding:30px;
    }
        .logos {
    text-align:center;
    padding:15px;
    display:block;    
    }
        .login-fd {
    width:100vw;
    text-align:center;
    }
    }*/
    .weixin,.wx-qr{text-align: center; }
    @media screen and (max-height: 850px){
        .login-fd{position:relative;margin-top:30px;}
    }
</style>